import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  baseUrl,
  noOfGullaList,
  rotliTypeList,
  batchSize,
} from "../comman/config";
import Select from "react-select";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { DateSessionFilter } from "../components/Filter/DateSessionFilter";
import toast, { Toaster } from 'react-hot-toast';

function BowlScreen() {
  const today = new Date().toISOString().split("T")[0];
  const [mealList, setMealList] = useState([]);
  const [consumptionDetail, setConsumptionDetail] = useState([]);
  const [methodList, setMethodList] = useState([]);
  const [methodType, setMethodType] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [batchIndex, setBatchIndex] = useState(0);
  const [bowlList, setBowlList] = useState([]);
  const [date, setDate] = useState(today);
  const [session, setSession] = useState("");
  const [formData, setFormData] = useState([]);
  // const [initialFormData, setIntialFormData] = useState([]);

  useEffect(() => {
    getBowlList();
    getMethodList();
  }, []);
  useEffect(() => {
    // Create initial formData and validationErrors based on mealList length
    const initialFormData = Array.from({ length: batchSize }, (_, index) => ({
      batch: index + 1,
      bowl_type: "",
      gross_weight: "",
      no_of_bowl_icebox: "",
      weight_of_1_roti: "",
      weight_of_1_empty_bowl_icebox: "",
      total_weight_of_empty_bowl_icebox: "",
      total_net_weight_of_filled_bowl_icebox: '',
      net_weight_of_filled_bowl_icebox: "",
      no_of_rotis_in_1_bowl_icebox: "",
      remarks_nos: "",
      bottom_diameter_d1: "",
      diameter_of_filled_height_d2: "",
      total_height_of_the_bowl: "",
      empty_height: "",
      filled_height: "",
      sensor_volume: '',
      manual_volume: "",
      avg_volume_of_1_filled_bowl: "",
      remarks_ltr: "",
      no_of_bowls_for_measurement: "",
      weight_of_1_empty_bowl: "",
      total_empty_weight: '',
      filled_weight: '',
      net_fg_weight: '',
      avg_weight_of_1_filled_bowl: "",
      remarks_kgs: "",
    }));
    setFormData(initialFormData);
    // setIntialFormData(initialFormData);
  }, [batchSize]);

  // useEffect(() => {

  //     if(mealList.length > 0 && activeIndex != null){
  //         getMethodType();
  //     }
  // }, [mealList, activeIndex]);

  useEffect(() => {
    if (mealList.length > 0 && activeIndex != null) {
      const item =
        mealList.find((item, index) => index === activeIndex) ?? null;
      const itemId = item.menu_item_id ?? null;
      const method = item?.measurements?.id ?? item.method;
      setMethodType(method);
      const fetchDetail = async () => {
        const condition = `date = '${date}' AND session = '${session.value}' AND menu_item_id = '${itemId}' AND method = '${method}'`;
        const response = await axios.get(
          `${baseUrl}/comman/list?table=bowl_screen&condition=${condition}`
        );

        const detail = response.data.data;
        getDetailDispaly(detail, method);
      };
      fetchDetail();
    }
  }, [mealList, activeIndex]);

  const getDetailDispaly = async (detail, method) => {
    try {
      if (detail) {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            bowl_type: "",
            gross_weight: "",
            no_of_bowl_icebox: "",
            weight_of_1_roti: "",
            weight_of_1_empty_bowl_icebox: "",
            total_weight_of_empty_bowl_icebox: "",
            total_net_weight_of_filled_bowl_icebox: '',
            net_weight_of_filled_bowl_icebox: "",
            no_of_rotis_in_1_bowl_icebox: "",
            remarks_nos: "",
            bottom_diameter_d1: "",
            diameter_of_filled_height_d2: "",
            total_height_of_the_bowl: "",
            empty_height: "",
            filled_height: "",
            sensor_volume: '',
            manual_volume: "",
            avg_volume_of_1_filled_bowl: "",
            remarks_ltr: "",
            no_of_bowls_for_measurement: "",
            weight_of_1_empty_bowl: "",
            total_empty_weight: '',
            filled_weight: '',
            net_fg_weight: '',
            avg_weight_of_1_filled_bowl: "",
            remarks_kgs: "",
          })
        );
        const updatedFormData = initialFormData.map((initialItem) => {
          const bowlObject = detail.find(
            (object) => object.batch == initialItem.batch
          );
          console.log(bowlObject);
          if (bowlObject) {
            const bowlId = bowlObject.bowl_type;
            const bowlItem = bowlList.find((item) => item.id == bowlId);
            const bowlItemObject = bowlItem
              ? { value: bowlItem.id, label: bowlItem.name }
              : { value: "", label: "" };
            initialItem.bowl_type = bowlItemObject;
            if (method == 1 || method == 4) {
              initialItem.no_of_bowls_for_measurement =
                bowlObject.no_of_bowls_for_measurement;
              initialItem.weight_of_1_empty_bowl =
                bowlObject.weight_of_1_empty_bowl;
              initialItem.total_empty_weight = bowlObject.total_empty_weight;
              initialItem.filled_weight = bowlObject.filled_weight;
              initialItem.net_fg_weight = bowlObject.net_fg_weight;
              initialItem.avg_weight_of_1_filled_bowl =
                bowlObject.avg_weight_of_1_filled_bowl;
              initialItem.remarks_kgs = bowlObject.remarks_kgs;
            }

            if (method == 2 || method == 5) {
              initialItem.bottom_diameter_d1 = bowlObject.bottom_diameter_d1;
              initialItem.diameter_of_filled_height_d2 =
                bowlObject.diameter_of_filled_height_d2;
              initialItem.total_height_of_the_bowl =
                bowlObject.total_height_of_the_bowl;
              initialItem.empty_height = bowlObject.empty_height;
              initialItem.filled_height = bowlObject.filled_height;
              initialItem.sensor_volume = bowlObject.sensor_volume;
              initialItem.manual_volume = bowlObject.manual_volume;
              initialItem.avg_volume_of_1_filled_bowl =
                bowlObject.avg_volume_of_1_filled_bowl;
              initialItem.remarks_ltr = bowlObject.remarks_ltr;
            }

            if (method == 3 || method == 6) {
              initialItem.gross_weight = bowlObject.gross_weight;
              initialItem.no_of_bowl_icebox = bowlObject.no_of_bowl_icebox;
              initialItem.weight_of_1_roti = bowlObject.weight_of_1_roti;
              initialItem.weight_of_1_empty_bowl_icebox =
                bowlObject.weight_of_1_empty_bowl_icebox;
              initialItem.total_weight_of_empty_bowl_icebox =
                bowlObject.total_weight_of_empty_bowl_icebox;
              initialItem.total_net_weight_of_filled_bowl_icebox =
                bowlObject.total_net_weight_of_filled_bowl_icebox;
              initialItem.net_weight_of_filled_bowl_icebox =
                bowlObject.net_weight_of_filled_bowl_icebox;
              initialItem.no_of_rotis_in_1_bowl_icebox =
                bowlObject.no_of_rotis_in_1_bowl_icebox;
              initialItem.remarks_nos = bowlObject.remarks_nos;
            }

            return { ...initialItem };
          } else {
            return { ...initialItem };
          }
        });
        setFormData(updatedFormData);
      } else {
        const initialFormData = Array.from(
          { length: batchSize },
          (_, index) => ({
            batch: index + 1,
            bowl_type: "",
            gross_weight: "",
            no_of_bowl_icebox: "",
            weight_of_1_roti: "",
            weight_of_1_empty_bowl_icebox: "",
            total_weight_of_empty_bowl_icebox: "",
            total_net_weight_of_filled_bowl_icebox: '',
            net_weight_of_filled_bowl_icebox: "",
            no_of_rotis_in_1_bowl_icebox: "",
            remarks_nos: "",
            bottom_diameter_d1: "",
            diameter_of_filled_height_d2: "",
            total_height_of_the_bowl: "",
            empty_height: "",
            filled_height: "",
            sensor_volume: '',
            manual_volume: "",
            avg_volume_of_1_filled_bowl: "",
            remarks_ltr: "",
            no_of_bowls_for_measurement: "",
            weight_of_1_empty_bowl: "",
            total_empty_weight: '',
            filled_weight: '',
            net_fg_weight: '',
            avg_weight_of_1_filled_bowl: "",
            remarks_kgs: "",
          })
        );
        setFormData(initialFormData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if ((methodType == 1 || methodType == 4) && formData[batchIndex]) {
      const no_of_bowls_for_measurement =
        formData[batchIndex].no_of_bowls_for_measurement;
      const filled_weight = formData[batchIndex].filled_weight;
      const weight_of_1_empty_bowl = formData[batchIndex].weight_of_1_empty_bowl;

      const total_empty_weight =
        (no_of_bowls_for_measurement * weight_of_1_empty_bowl).toFixed(2) ?? 0;
      const net_fg_weight =
        (filled_weight - total_empty_weight).toFixed(2) ?? 0;
      // const avg_weight_of_1_filled_bowl =
      //   no_of_bowls_for_measurement > 0
      //     ? (net_fg_weight / no_of_bowls_for_measurement).toFixed(2)
      //     : 0;
      console.log(no_of_bowls_for_measurement);
      const weight_sum = formData.reduce((accumulator, currentValue) => {
        let type;
        if (typeof currentValue.net_fg_weight === "string") {
          const floatValue = parseFloat(currentValue.net_fg_weight);
          if (!isNaN(floatValue)) {
            type = floatValue; // If valid, assign the float value
          } else {
            type = 0; // If not valid, assign 0 or handle it according to your requirement
          }
        } else {
          type = currentValue.net_fg_weight;
        }
        return accumulator + type;
      }, 0);
      
      const bowl_sum = formData.reduce((accumulator, currentValue) => {
        let type;
        if (typeof currentValue.no_of_bowls_for_measurement === "string") {
          const floatValue = parseFloat(currentValue.no_of_bowls_for_measurement);
          if (!isNaN(floatValue)) {
            type = floatValue; // If valid, assign the float value
          } else {
            type = 0; // If not valid, assign 0 or handle it according to your requirement
          }
        } else {
          type = currentValue.no_of_bowls_for_measurement;
        }
        return accumulator + type;
      }, 0);
     
      const avg_weight_of_1_filled_bowl = (weight_sum / bowl_sum).toFixed(2);
      if (formData[batchIndex].total_empty_weight !== total_empty_weight) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            total_empty_weight: total_empty_weight,
          };
          return updatedFormData;
        });
      }

      if (formData[batchIndex].net_fg_weight !== net_fg_weight) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            net_fg_weight: net_fg_weight,
          };
          return updatedFormData;
        });
      }

      if (
        formData[batchIndex].avg_weight_of_1_filled_bowl !==
        avg_weight_of_1_filled_bowl
      ) {
        // setFormData((prevFormData) => {
        //   const updatedFormData = [...prevFormData];
        //   updatedFormData[batchIndex] = {
        //     ...updatedFormData[batchIndex],
        //     avg_weight_of_1_filled_bowl: avg_weight_of_1_filled_bowl,
        //   };
        //   return updatedFormData;
        // });
        setFormData((prevFormData) => {
          const updatedFormData = prevFormData.map(item => ({
              ...item,
              avg_weight_of_1_filled_bowl: avg_weight_of_1_filled_bowl,
          }));
          return updatedFormData;
        });
      }
    } else if ((methodType == 2 || methodType == 5) && formData[batchIndex]) {
      const total_height_of_the_bowl =
        formData[batchIndex].total_height_of_the_bowl;
      const empty_height = formData[batchIndex].empty_height;
      const d1 = formData[batchIndex].bottom_diameter_d1;
      const d2 = formData[batchIndex].diameter_of_filled_height_d2;
      const filled_height_value = formData[batchIndex].filled_height;

      const filled_height = isNaN(total_height_of_the_bowl - empty_height)
        ? 0
        : (total_height_of_the_bowl - empty_height).toFixed(2) ?? 0;
      console.log(filled_height_value);
      const volume = (1 / 3) * 3.14 * filled_height * ((d1 / 2) * (d1 / 2) + (d1 / 2) * (d2 / 2) + (d2 / 2) * (d2 / 2));
      const manual_volume = isNaN(volume) ? 0 : (volume / 1000).toFixed(2);

      const volume_sum = formData.reduce((accumulator, currentValue) => {
        let type;
        if (typeof currentValue.manual_volume === "string") {
          const floatValue = parseFloat(currentValue.manual_volume);
          if (!isNaN(floatValue)) {
            type = floatValue; // If valid, assign the float value
          } else {
            type = 0; // If not valid, assign 0 or handle it according to your requirement
          }
        } else {
          type = currentValue.manual_volume;
        }
        return accumulator + type;
      }, 0);
      
      const count = formData.filter(obj => obj.manual_volume).length;
      
      const avg_volume_of_1_filled_bowl = (volume_sum / count).toFixed(2);
      if (formData[batchIndex].filled_height !== filled_height) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            filled_height: filled_height,
          };
          return updatedFormData;
        });
      }

      if (formData[batchIndex].manual_volume !== manual_volume) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            manual_volume: manual_volume,
          };
          return updatedFormData;
        });
      }
      if (
        formData[batchIndex].avg_volume_of_1_filled_bowl !==
        avg_volume_of_1_filled_bowl
      ) {
        // setFormData((prevFormData) => {
        //   const updatedFormData = [...prevFormData];
        //   updatedFormData[batchIndex] = {
        //     ...updatedFormData[batchIndex],
        //     avg_volume_of_1_filled_bowl: avg_volume_of_1_filled_bowl,
        //   };
        //   return updatedFormData;
        // });
        setFormData((prevFormData) => {
          const updatedFormData = prevFormData.map(item => ({
              ...item,
              avg_volume_of_1_filled_bowl: avg_volume_of_1_filled_bowl,
          }));
          return updatedFormData;
      });
      }
    }

    if ((methodType == 3 || methodType == 6) && formData[batchIndex]) {
      const no_of_bowl_icebox = formData[batchIndex].no_of_bowl_icebox;
      const weight_of_1_empty_bowl_icebox =
        formData[batchIndex].weight_of_1_empty_bowl_icebox;
      const gross_weight = formData[batchIndex].gross_weight;
      const weight_of_1_roti = formData[batchIndex].weight_of_1_roti;

      const total_weight_of_empty_bowl_icebox =
        (no_of_bowl_icebox * weight_of_1_empty_bowl_icebox).toFixed(2) ?? 0;
      const total_net_weight_of_filled_bowl_icebox =
        (gross_weight - total_weight_of_empty_bowl_icebox).toFixed(2) ?? 0;
      const net_weight_of_filled_bowl_icebox =
        no_of_bowl_icebox > 0
          ? (
              total_net_weight_of_filled_bowl_icebox / no_of_bowl_icebox
            ).toFixed(2)
          : 0;
      const no_of_rotis_in_1_bowl_icebox =
        weight_of_1_roti > 0
          ? (net_weight_of_filled_bowl_icebox / weight_of_1_roti).toFixed(2)
          : 0;
          // const net_weight_sum = formData.reduce((accumulator, currentValue) => {
          //   let type;
          //   if (typeof currentValue.net_weight_of_filled_bowl_icebox === "string") {
          //     const floatValue = parseFloat(currentValue.net_weight_of_filled_bowl_icebox);
          //     if (!isNaN(floatValue)) {
          //       type = floatValue; // If valid, assign the float value
          //     } else {
          //       type = 0; // If not valid, assign 0 or handle it according to your requirement
          //     }
          //   } else {
          //     type = currentValue.net_weight_of_filled_bowl_icebox;
          //   }
          //   return accumulator + type;
          // }, 0);
          
          // const count = formData.filter(obj => obj.net_weight_of_filled_bowl_icebox).length;
          
          // const no_of_rotis_in_1_bowl_icebox = (net_weight_sum / count).toFixed(2);

      if (
        formData[batchIndex].total_weight_of_empty_bowl_icebox !==
        total_weight_of_empty_bowl_icebox
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            total_weight_of_empty_bowl_icebox:
              total_weight_of_empty_bowl_icebox,
          };
          return updatedFormData;
        });
      }

      if (
        formData[batchIndex].total_net_weight_of_filled_bowl_icebox !==
        total_net_weight_of_filled_bowl_icebox
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            total_net_weight_of_filled_bowl_icebox:
              total_net_weight_of_filled_bowl_icebox,
          };
          return updatedFormData;
        });
      }

      if (
        formData[batchIndex].net_weight_of_filled_bowl_icebox !==
        net_weight_of_filled_bowl_icebox
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            net_weight_of_filled_bowl_icebox: net_weight_of_filled_bowl_icebox,
          };
          return updatedFormData;
        });
      }

      if (
        formData[batchIndex].no_of_rotis_in_1_bowl_icebox !==
        no_of_rotis_in_1_bowl_icebox
      ) {
        setFormData((prevFormData) => {
          const updatedFormData = [...prevFormData];
          updatedFormData[batchIndex] = {
            ...updatedFormData[batchIndex],
            no_of_rotis_in_1_bowl_icebox: no_of_rotis_in_1_bowl_icebox,
          };
          return updatedFormData;
        });
      //   setFormData((prevFormData) => {
      //     const updatedFormData = prevFormData.map(item => ({
      //         ...item,
      //         no_of_rotis_in_1_bowl_icebox: no_of_rotis_in_1_bowl_icebox,
      //     }));
      //     return updatedFormData;
      //   });
      }
    }
  }, [formData, methodType, batchIndex]);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const getBowlList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=bowl_master&condition=status=1`
    );
    setBowlList(response.data.data);
  };

  const getMethodList = async () => {
    const response = await axios.get(
      `${baseUrl}/comman/list?table=method_master&condition=status=1`
    );
    setMethodList(response.data.data);
  };

  const getMethodType = async () => {
    const item_id = mealList.find((item, index) => index == activeIndex);
    setMethodType(item_id.measurements.id);
  };

  const handelFilterChange = async (date, session) => {
    setDate(date);
    setSession(session);
    if (date && session) {
      const condition = ` m.session='${session.value}' AND m.date='${date}'`;
      const response = await axios.get(
        `${baseUrl}/meal_master/item_list?condition=${condition}`
      );
      const newData = response.data.data.reduce((acc, obj) => {
        const measurementsArray = obj?.measurements?.split(",") ?? [obj.method];
        measurementsArray.forEach((measurement) => {
          acc.push({
            ...obj,
            measurements: methodList.find((v) => v.id == measurement) ?? {},
          });
        });
        return acc;
      }, []);

      console.log(newData);
      setMealList(newData);
    }
  };

  const handleInputChange = (index) => (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index] = { ...updatedFormData[index], [name]: value };
      return updatedFormData;
    });
    setBatchIndex(index);
  };

  const handleSelectChange = async (index, data, option) => {
    setBatchIndex(index);
    if (methodType == 1 || methodType == 4) {
      const empty_weight =
        bowlList.find((item) => item.id == data.value).bowl_weight_empty ?? 0;
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
          weight_of_1_empty_bowl: empty_weight,
        };
        return updatedFormData;
      });
    } else if (methodType == 2 || methodType == 5) {
      const bowlItem = bowlList.find((item) => item.id == data.value);

      const bottom_diameter_d1 = bowlItem.bowl_diameter_bottom ?? 0;
      const diameter_of_filled_height_d2 = bowlItem.bowl_diameter_marked ?? 0;
      const total_height_of_the_bowl = bowlItem.bowl_total_height ?? 0;

      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
          bottom_diameter_d1: bottom_diameter_d1,
          diameter_of_filled_height_d2: diameter_of_filled_height_d2,
          total_height_of_the_bowl: total_height_of_the_bowl,
        };
        return updatedFormData;
      });
    } else if (methodType == 3 || methodType == 6) {
      const empty_weight =
        bowlList.find((item) => item.id == data.value).bowl_weight_empty ?? 0;
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
          weight_of_1_empty_bowl_icebox: empty_weight,
        };
        return updatedFormData;
      });
    } else {
      setFormData((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [option.name]: data,
        };
        return updatedFormData;
      });
    }
  };

  const handleSave = async (e, index) => {
    e.preventDefault();

    const menu_item_id =
      mealList.find((item, i) => i == activeIndex)?.menu_item_id ?? 0;
    const combineData = {
      formData: formData[index],
      date: date,
      session: session,
      methodType: methodType,
      menu_item_id: menu_item_id,
    };
    console.log(combineData);
    try {
      // setPopupOpen(true);

      await axios.post(`${baseUrl}/bowl_screen/batch`, combineData);
      toast.success('Data successfully saved!');
    } catch (error) {
      toast.error('Failed to submit the form.');
      // setPopupOpen(false);
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center py-4 px-6">
        <h1 className="text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl font-bold">Bowl</h1>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <div className="relative  shadow-md sm:rounded-lg">
        <DateSessionFilter handleFilterChange={handelFilterChange} />
        <div>
          {mealList.map((item, index) => (
            <div key={index} className="border-b mt-4">
              <div
                className="flex justify-between items-center p-4 cursor-pointer accordion-title bg-orange-400 border rounded"
                onClick={() => handleClick(index)}
              >
                <span>
                  {`${item.item_name} (${item.eng_name})`} (
                  {item.measurements.name})
                </span>
                {index === activeIndex ? (
                  <ChevronUpIcon className="w-6 h-6" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6" />
                )}
              </div>
              {index === activeIndex && (
                <div className=" p-4 bg-gray-100">
                  <div className="flex overflow-x-auto">
                    <div className="flex mt-10 flex-col whitespace-nowrap">
                      <div className="font-bold mt-2">Batch</div>
                      <div className="font-bold  mt-7">Bowl Type</div>

                      {(item?.measurements?.id == 1 ||
                        item?.measurements?.id == 4) && (
                        <>
                          <div className="font-bold mt-5">
                            No of bowls for measurement
                          </div>
                          <div className="font-bold mt-7 ">
                            Weight of 1 Empty bowl(kgs.)
                          </div>
                          <div className="font-bold mt-7 ">
                            Total Empty weight (kgs.)
                          </div>
                          <div className="font-bold mt-7 ">
                            Filled weight (kgs.)
                          </div>
                          <div className="font-bold mt-7 ">
                            Net FG weight (kgs.)
                          </div>
                          <div className="font-bold mt-7">
                            Avg. Weight of 1 Filled bowl (kgs.)
                          </div>
                        </>
                      )}
                      {(item?.measurements?.id == 2 ||
                        item?.measurements?.id == 5) && (
                        <>
                          <div className="font-bold  mt-6">
                            Bottom Diameter -D1 (cms.)
                          </div>
                          <div className="font-bold  mt-7">
                            Diameter of filled height - D2 (cms)
                          </div>
                          <div className="font-bold  mt-6">
                            Total height of the Bowl (cms)
                          </div>
                          <div className="font-bold  mt-7">
                            Empty height (cms)
                          </div>
                          <div className="font-bold mt-9">
                            Filled height (Cms.)
                          </div>
                          <div className="font-bold mt-7">Sensor Volume</div>
                          <div className="font-bold  mt-7">Manual Volume</div>
                          <div className="font-bold  mt-7">
                            Avg. Volume of 1 filled bowl (ltrs.)
                          </div>
                        </>
                      )}
                      {(item?.measurements?.id == 3 ||
                        item?.measurements?.id == 6) && (
                        <>
                          <div className="font-bold  mt-6">
                            Gross weight (Ice box+Rotli)
                          </div>
                          <div className="font-bold  mt-7">
                            No. of Bowl/icebox
                          </div>
                          <div className="font-bold  mt-7">
                            Weight of 1 roti
                          </div>
                          <div className="font-bold  mt-7">
                            Weight of 1 empty Icebox / bowl
                          </div>
                          <div className="font-bold  mt-7">
                            Total Weight of empty Icebox / bowl
                          </div>
                          <div className="font-bold  mt-7">
                            Total Net weight of filled Icebox / bowl
                          </div>
                          <div className="font-bold  mt-7">
                            Net weight of 1 filled Icebox / bowl
                          </div>
                          <div className="font-bold  mt-7">
                            No of rotis in 1 Icebox / bowl
                          </div>
                        </>
                      )}
                      <div className="font-bold  mt-7">Remarks</div>
                    </div>
                    {Array.from({ length: batchSize }, (_, index) => (
                      <div
                        key={index}
                        className="gap-5 mb-4 mt-10 ms-2 flex-shrink-0 w-52"
                      >
                        <div className="border p-2">{index + 1}</div>
                        <div className="border p-2">
                          <Select
                            classNamePrefix="select"
                            styles={{
                              placeholder: (provided) => ({
                                ...provided,
                                fontSize: "0.80rem",
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: '#FFFAF0', 
                                fontSize: '16px', 
                              }),
                            }}
                            name="bowl_type"
                            value={formData?.[index].bowl_type}
                            onChange={(selectedOption, name) => {
                              handleSelectChange(index, selectedOption, name);
                            }}
                            placeholder="Bowl Type"
                            options={bowlList.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))}
                            filterOption={(option, inputValue) => {
                              return option.data.label
                                .toLowerCase()
                                .includes(inputValue.toLowerCase());
                            }}
                          />
                        </div>

                        {(item?.measurements?.id == 1 ||
                          item?.measurements?.id == 4) && (
                          <>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_bowls_for_measurement"
                                name="no_of_bowls_for_measurement"
                                value={
                                  formData?.[index]?.no_of_bowls_for_measurement
                                }
                                placeholder="No of bowls for measurement"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_empty_bowl"
                                name="weight_of_1_empty_bowl"
                                value={
                                  formData?.[index]?.weight_of_1_empty_bowl
                                }
                                placeholder="Weight of 1 empty  bowl"
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_empty_weight"
                                name="total_empty_weight"
                                placeholder="Total Empty weight (kgs.)"
                                disabled
                                value={formData?.[index]?.total_empty_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="filled_weight"
                                name="filled_weight"
                                placeholder="  Filled weight (kgs.)"
                                value={formData?.[index]?.filled_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"  
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="net_fg_weight"
                                name="net_fg_weight"
                                placeholder="Net FG weight (kgs.)"
                                disabled
                                value={formData?.[index]?.net_fg_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="avg_weight_of_1_filled_bowl"
                                disabled
                                name="avg_weight_of_1_filled_bowl"
                                placeholder=" Avg. Weight of 1 Filled bowl (kgs.)"
                                value={
                                  formData?.[index]?.avg_weight_of_1_filled_bowl
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_kgs"
                                name="remarks_kgs"
                                placeholder="Remarks"
                                value={formData?.[index]?.remarks_kgs}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                          </>
                        )}
                        {(item?.measurements?.id == 2 ||
                          item?.measurements?.id == 5) && (
                          <>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="bottom_diameter_d1"
                                disabled
                                name="bottom_diameter_d1"
                                placeholder="Bottom Diameter -D1 (cms.)"
                                value={formData?.[index]?.bottom_diameter_d1}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="diameter_of_filled_height_d2"
                                name="diameter_of_filled_height_d2"
                                placeholder="Diameter of filled height - D2 (cms)"
                                value={
                                  formData?.[index]
                                    ?.diameter_of_filled_height_d2
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_height_of_the_bowl"
                                disabled
                                name="total_height_of_the_bowl"
                                placeholder="Total height of the Bowl (cms)"
                                value={
                                  formData?.[index]?.total_height_of_the_bowl
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="empty_height"
                                name="empty_height"
                                placeholder="Empty height (cms)"
                                value={formData?.[index]?.empty_height}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="filled_height"
                                name="filled_height"
                                placeholder="Filled height (Cms.)"
                                disabled
                                value={formData?.[index]?.filled_height}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="sensor_volume"
                                name="sensor_volume"
                                placeholder="Sensor Volume"
                                value={formData?.[index]?.sensor_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="manual_volume"
                                placeholder="Manual Volume"
                                disabled
                                name="manual_volume"
                                value={formData?.[index]?.manual_volume}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="avg_volume_of_1_filled_bowl"
                                placeholder="Avg. Volume of 1 filled bowl (ltrs.)"
                                disabled
                                name="avg_volume_of_1_filled_bowl"
                                value={
                                  formData?.[index]?.avg_volume_of_1_filled_bowl
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_ltr"
                                name="remarks_ltr"
                                placeholder="Remarks"
                                value={formData?.[index]?.remarks_ltr}
                                onChange={handleInputChange(index)}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                          </>
                        )}
                        {(item?.measurements?.id == 3 ||
                          item?.measurements?.id == 6) && (
                          <>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="gross_weight"
                                name="gross_weight"
                                placeholder="Gross weight (Ice box+Rotli)"
                                value={formData?.[index]?.gross_weight}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_bowl_icebox"
                                placeholder="No. of Bowl/icebox"
                                name="no_of_bowl_icebox"
                                value={formData?.[index]?.no_of_bowl_icebox}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_roti"
                                name="weight_of_1_roti"
                                placeholder="Weight of 1 roti"
                                value={formData?.[index]?.weight_of_1_roti}
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="weight_of_1_empty_bowl_icebox"
                                name="weight_of_1_empty_bowl_icebox"
                                placeholder="Weight of 1 empty Icebox / bowl"
                                value={
                                  formData?.[index]
                                    ?.weight_of_1_empty_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_weight_of_empty_bowl_icebox"
                                disabled
                                name="total_weight_of_empty_bowl_icebox"
                                placeholder="Total Weight of empty Icebox / bowl"
                                value={
                                  formData?.[index]
                                    ?.total_weight_of_empty_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="total_net_weight_of_filled_bowl_icebox"
                                disabled
                                name="total_net_weight_of_filled_bowl_icebox"
                                placeholder="Total Net weight of filled Icebox / bowl"
                                value={
                                  formData?.[index]
                                    ?.total_net_weight_of_filled_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="net_weight_of_filled_bowl_icebox"
                                disabled
                                name="net_weight_of_filled_bowl_icebox"
                                placeholder="Net weight of 1 filled Icebox / bowl"
                                value={
                                  formData?.[index]
                                    ?.net_weight_of_filled_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="number"
                                step="0.01"
                                id="no_of_rotis_in_1_bowl_icebox"
                                placeholder="No of rotis in 1 Icebox / bowl"
                                disabled
                                name="no_of_rotis_in_1_bowl_icebox"
                                value={
                                  formData?.[index]
                                    ?.no_of_rotis_in_1_bowl_icebox
                                }
                                onChange={handleInputChange(index)}
                                onWheel={(e) => e.target.blur()}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-lime-300"
                              />
                            </div>
                            <div className="border p-2">
                              <input
                                type="text"
                                id="remarks_nos"
                                placeholder="Remarks"
                                name="remarks_nos"
                                value={formData?.[index]?.remarks_nos}
                                onChange={handleInputChange(index)}
                                className="w-full border rounded-md placeholder:text-sm p-1 bg-orange-50" 
                              />
                            </div>
                          </>
                        )}

                        {/* <div className="border p-2">
                                            <Select
                                                classNamePrefix="select"
                                                name="timeslot"  
                                                value={formData?.[index].timeslot} 
                                                onChange={(selectedOption, name) => {
                                                    handleSelectChange(index,selectedOption, name);
                                                }}                                     
                                                options={timeSlotList.map(item => ({
                                                    value: item.value,
                                                    label: item.label
                                                }))}
                                                filterOption={(option, inputValue) => {
                                                    return option.data.label.toLowerCase().includes(inputValue.toLowerCase());
                                                }}
                                            />
                                        </div>
                                        <div className="border p-2"><input type="text" id="requisition_no" name="requisition_no" value={formData?.[index]?.requisition_no} onChange={handleInputChange(index)} className="w-full border rounded-md placeholder:text-sm p-1" />	</div> */}

                        <div className="border p-2">
                          <button
                            onClick={(e) => {
                              handleSave(e, index);
                            }}
                            className="px-4 py-2 bg-green-500 text-white rounded-md placeholder:text-sm p-1 mr-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BowlScreen;
